import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Company = ({ location }) => (
  <Layout>
    <SEO 
      pagetitle="会社概要"
      pagedesc="交通広告代理店 広文社についての会社情報になります。なお、詳しい企業情報に関しましては、お問い合わせフォームよりお問い合わせください。"
      pagepath={location.pathname}
    />
    <section className="area">
    <div className="container">
      <h2 className="bar">企業情報</h2>
    　　　<table class="company-table">
            <tbody>
              <tr>
                <th class="">会 社 名</th>
                <td class="">広文社</td>
              </tr>
              <tr>
                <th class="">代 表 者</th>
                <td class="">茂呂　昌弘</td>
              </tr>
              <tr>
                <th class="">設　立</th>
                <td class="">1983年9月7日</td>
              </tr>
              <tr>
                <th class="">T E L</th>
                <td class="">03-3834-8290</td>
              </tr>
              <tr>
                <th class="">事業内容</th>
                <td class="">交通広告代理業（ 東日本鉄道広告会会員 ）、イベント企画立案、印刷業務全般、WEB広告全般、広告メディア取付制作、屋外広告全般</td>
              </tr>
              <tr>
                <th class="">取引銀行</th>
                <td class="">みずほ銀行、栃木銀行、足利銀行</td>
              </tr>
              <tr>
                <th class="">所 在 地</th>
                <td class="">〒110-0005 東京都台東区上野6-16-22 上野TGビル9F</td>
              </tr>
            </tbody>
        </table>
        <div class="map">
          <iframe title="gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.6515228596154!2d139.7740819155711!3d35.71019213598989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188e9f1a7d4c45%3A0x81c87b6955bffec3!2z44CSMTEwLTAwMDUg5p2x5Lqs6YO95Y-w5p2x5Yy65LiK6YeO77yW5LiB55uu77yR77yW4oiS77yS77ySIOS4iumHju-8tO-8p-ODk-ODqyA5Rg!5e0!3m2!1sja!2sjp!4v1615625196738!5m2!1sja!2sjp" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" />
        </div>
        </div>
    </section>
  </Layout>
)

export default Company